<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.sozlesmeAdi }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SÖZLEŞMELER" entityName="bm_sozlesmeler"></EntityHeader>
					<div class="grid">
						
						
						<div class="col-6">
							<div class="field p-fluid">
								<label for="adi">Sözleşme Adı <span style="color:red">*</span></label>
								<InputText id="adi" type="text" v-model="mainData.sozlesmeAdi" />
								<span v-if="v$.mainData.sozlesmeAdi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.sozlesmeAdi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma"
									label="Firma" entityName="account" nameField="name" :state="true" :required="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="durum">Durum</label>
								<Dropdown id="durum" v-model="mainData.durum" :options="SM_durum" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true"
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="ilgiliKisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgiliKisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						
						
						
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TARİHLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifTarihi">Teklif Tarihi</label>
								<Calendar showButtonBar id="teklifTarihi" v-model="mainData.teklifTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifSozlesmeSuresi">Teklif Sözleşme Süresi</label>
								<Dropdown id="teklifSozlesmeSuresi" v-model="mainData.teklifSozlesmeSuresi" :options="SM_teklifSozlesmeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Teklif Sözleşme Süresi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeBaslangicTarihi">Sözleşme Başlangıç Tarihi</label>
								<Calendar showButtonBar id="sozlesmeBaslangicTarihi" v-model="mainData.sozlesmeBaslangicTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeSuresi">Sözleşme Süresi</label>
								<Dropdown id="sozlesmeSuresi" v-model="mainData.sozlesmeSuresi" :options="SM_sozlesmeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Sözleşme Süresi" :showClear="true" @change="changeSozlesmeSuresi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeBitisTarihi">Sözleşme Bitiş Tarihi</label>
								<Calendar showButtonBar id="sozlesmeBitisTarihi" v-model="mainData.sozlesmeBitisTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>FİYATLANDIRMA</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisSaatindirimi">Servis Saat İndirimi</label>
								<InputNumber id="servisSaatindirimi" v-model="mainData.servisSaatindirimi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="toplamTutar">Toplam Tutar</label>
								<InputNumber id="toplamTutar" v-model="mainData.toplamTutar" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimTutar">İndirim Tutar</label>
								<InputNumber id="indirimTutar" v-model="mainData.indirimTutar" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimOran">İndirim Oranı %</label>
								<InputNumber id="indirimOran" v-model="mainData.indirimOran" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaEdildimi">Fatura Edildi mi?</label>
								<Dropdown id="faturaEdildimi" v-model="mainData.faturaEdildimi" :options="SM_faturaEdildimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Fatura Edildi mi?" :showClear="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaTarihi">Fatura Tarihi</label>
								<Calendar showButtonBar id="faturaTarihi" v-model="mainData.faturaTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						


					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10019,
			mainData: {},
			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
				}
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let bugun = new Date();
		const year = bugun.getFullYear();
		const month = bugun.getMonth();
		const day = bugun.getDate();
		bugun = new Date(year, month, day);

		this.mainData.sozlesmeAdi = 'YILLIK BAKIM SÖZLEŞMESİ ' + year;
		this.mainData.sozlesmeSatirlariniOlustur = false;
		this.mainData.servisSaatindirimi = 15;
		this.mainData.durum = 930710002;
		this.mainData.teklifTarihi = bugun;

		setTimeout(() => {
			let paraBirimi = { "Value": "4f6220a0-6aeb-dc11-a742-000e0c3f151f", "Name": "Euro" };
			this.paraBirimiSelected = paraBirimi;
			this.$refs.entity_paraBirimi.setDefaultData(paraBirimi);

			this.mainData.sahibidId = this.profileData.ID;
		}, 1000);
		
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		firmaSelected: {
			get: function () {
				debugger;
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		SM_durum: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durum');
		},
		SM_sozlesmeSuresi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_sozlemesuresi');
		},
		SM_teklifSozlesmeSuresi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_teklifsozlesmesuresi');
		},
		SM_faturaEdildimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_faturaedildimi');
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getSozlesmeCreate(this.mainData);
					debugger;
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							setTimeout(() => {
								this.$router.push({ name: 'bm_sozlesmeler', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		changeSozlesmeSuresi(event){
			debugger;
			console.log(event);

			if (event.value) {
				let sozlesmeBaslangicTarihi = this.mainData.sozlesmeBaslangicTarihi;
				if (sozlesmeBaslangicTarihi == null) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: "Lütfen önce Sözleşme Başlangıç Tarihini doldurunuz!", life: 3500});
				}
				else {
					const year = this.mainData.sozlesmeBaslangicTarihi.getFullYear();
					const month = this.mainData.sozlesmeBaslangicTarihi.getMonth();
					const day = this.mainData.sozlesmeBaslangicTarihi.getDate();
					const bitisTarihi = new Date(year + event.value, month, day);
					this.mainData.sozlesmeBitisTarihi = bitisTarihi;
				}
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Sözleşmeler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				sozlesmeAdi: {
					required : helpers.withMessage('Sözleşme Adı alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>

</style>
